import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";

import "./App.css";
import {
  setDefaultLanguage,
  getDefaultLanguage,
  getTranslation,
} from "./translation";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ToggleButton from "@mui/material/ToggleButton/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import Button from "@mui/material/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Cookies from "universal-cookie";
import LogoImg from "./img/zk_logo.png"; // Adjust the path accordingly
import CloseIcon from "@mui/icons-material/Close";

import Home from "./views/Home";
import About from "./views/About";
import AdminWelcome from "./views/admin/AdminWelcome";
import News from "./views/News";
import LoginPage from "./views/LoginPage";
import CurrentNews from "./views/CurrentNews";
import { decode } from "punycode";
import Contact from "./views/Contact";
import OurTeam from "./views/OurTeeam";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LegalAreas from "./views/LegalAreas";

type Segment = "booking" | "services" | "about" | "prices" | "news" | "home";

type Language = "mk" | "en" | "de";
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove border
              // borderBottomLeftRadius: '20px', // Add border radius to bottom-left
              // borderBottomRightRadius: '20px', // Add border radius to bottom-right
              boxShadow: "0px 16px 12px -12px rgba(0, 0, 0, 0.2)", // Box shadow at the bottom
            },
            "&.Mui-focused fieldset": {
              border: "1px solid gray", // Add gray border when focused
              borderBottomLeftRadius: "20px", // Maintain border radius on bottom-left when focused
              borderBottomRightRadius: "20px", // Maintain border radius on bottom-right when focused
            },
          },
        },
      },
    },
  },
});

function App(): JSX.Element {
  // Use useEffect hook to fetch all the data when the component mounts
  useEffect(() => {
    fetchAllData();
    if (location.pathname != "/") {
      const storedSegment = getSelectedSegmentFromLocalStorage();
      setSelectedSegment(storedSegment);
    } else {
      setSelectedSegment("home");
    }
  }, []);

  const fetchAllData = async () => {
    // try {
    //   const response = await fetch(
    //     "https://numbers.nikolakolevski.com/api/client/services"
    //   );
    //   const data = await response.json();
    //   setServices(data); // Update the services state with fetched data
    //   const package_response = await fetch(
    //     "https://numbers.nikolakolevski.com/api/client/packages"
    //   );
    //   const package_data = await package_response.json();
    //   setPackages(package_data); // Update the services state with fetched data
    //   const news_response = await fetch(
    //     "https://numbers.nikolakolevski.com/api/client/news"
    //   );
    //   const news_data = await news_response.json();
    //   setNews(news_data); // Update the services state with fetched data
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  };
  const TikTokIcon = ({
    backgroundColor = "#DA327D",
    logoColor = "#fff",
    size = 36,
  }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width={size}
        height={size}
        style={{ overflow: "hidden", stroke: "none", outline: "none" }}
        // fill={logoColor}
      >
        <path
          d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z"
          fill={backgroundColor}
        />
        <path
          d="M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"
          fill={logoColor}
        />
      </svg>
    );
  };

  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   // debugger
  //   const decodedPath = decodeURIComponent(location.pathname);
  //   if (decodedPath === '/за-нас') {
  //     setDefaultLanguage('mk'); // Assuming 'mk' is the code for Macedonian
  //     setSelectedLanguage("mk");
  //   }
  //   // else {
  //   //   setDefaultLanguage('en'); // Default to English for other paths
  //   //   setSelectedLanguage("en");
  //   // }
  // }, [location]);
  const [services, setServices] = useState([]);
  const [packages, setPackages] = useState([]);
  const [news, setNews] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [token, setToken] = useState("");
  const [selectedSegment, setSelectedSegment] = useState<Segment>("home");
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    getDefaultLanguage()
  );

  const saveSelectedSegmentToLocalStorage = (segment: Segment) => {
    localStorage.setItem("selectedSegment", segment);
  };

  const getSelectedSegmentFromLocalStorage = (): Segment => {
    const storedSegment = localStorage.getItem("selectedSegment");
    return storedSegment ? (storedSegment as Segment) : "home"; // Default to 'home' if nothing is found in local storage
  };

  // Update the default language whenever the language state changes
  useEffect(() => {
    setDefaultLanguage(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    // setDefaultLanguage(selectedLanguage);
    const mk_list: string[] = ["/за-нас", "/новости", "/нашиот-тим", "контакт"]; // List of strings
    const en_list: string[] = ["/about-us", "/news", "/our-team", "contact"]; // List of strings
    const de_list: string[] = ["apple", "banana", "orange", "контакт"]; // List of strings

    const decodedPath = decodeURIComponent(location.pathname);
    if (mk_list.includes(decodedPath) || /\/нашиот-тим\/[^/]+-[^/]+/i.test(decodedPath)) {
      setDefaultLanguage("mk");
        setSelectedLanguage("mk");
      }
      if (en_list.includes(decodedPath)|| /\/our-team\/[^/]+-[^/]+/i.test(decodedPath)) {
        setDefaultLanguage("en");
        setSelectedLanguage("en");
      }
  }, [location]);

  // language
  // Define a mapping between languages and their routes

  // Handler for changing language
  const changeLanguage = (newLanguage: Language) => {
    setSelectedLanguage(newLanguage); // Update the language state
    setDefaultLanguage(newLanguage); // Update the default language

    // Determine the current page (e.g., 'about', 'home', etc.)
    const decodedPath = decodeURIComponent(location.pathname);

    const currentPage = location.pathname; // Implement this function based on your routing logic

    if (decodedPath === "/") {
    } else if(decodedPath === "/нашиот-тим" || decodedPath === "/our-team" ||  /\/нашиот-тим\/[^/]+-[^/]+/i.test(decodedPath) || /\/our-team\/[^/]+-[^/]+/i.test(decodedPath)) {
      if (/\/нашиот-тим\/[^/]+-[^/]+/i.test(decodedPath) || /\/our-team\/[^/]+-[^/]+/i.test(decodedPath)) {
        debugger
        console.log(location)
        if (newLanguage === "mk") {
          navigate("/нашиот-тим/"+decodedPath.split("/")[decodedPath.split("/").length-1]);
        }
        if (newLanguage === "en") {
          navigate("/our-team/"+decodedPath.split("/")[decodedPath.split("/").length-1]);
        }
      } else {
        if (newLanguage === "mk") {
          navigate("/нашиот-тим");
        }
        if (newLanguage === "en") {
          navigate("/our-team");
        }
      }

    } else if(decodedPath === "/нашиот-тим" || decodedPath === "/our-team" ) {
      if (newLanguage === "mk") {
        navigate("/за-нас");
      }
      if (newLanguage === "en") {
        navigate("/about-us");
      }
    }else if(decodedPath === "/контакт" || decodedPath === "/contact" ) {
      if (newLanguage === "mk") {
        navigate("/контакт");
      }
      if (newLanguage === "en") {
        navigate("/contact");
      }
    }
    // Navigate to the corresponding route in the selected language
    // const newPath = languageRoutes[newLanguage][decodedPath];
    // if (newPath) {
    //   navigate("/about-us");
    // }

    toggleDropdown();
  };

  const scrollToSegment = (segment: Segment) => {
    const element = document.getElementById(segment);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Function to fetch services
  const fetchServices = async () => {
    // try {
    //   const response = await fetch(
    //     "https://numbers.nikolakolevski.com/api/client/services"
    //   );
    //   const data = await response.json();
    //   setServices(data); // Update the services state with fetched data
    // } catch (error) {
    //   console.error("Error fetching services:", error);
    // }
  };
  const fetchPackages = async () => {
    try {
      const response = await fetch(
        "https://numbers.nikolakolevski.com/api/client/packages"
      );
      const data = await response.json();
      setPackages(data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };
  const fetchNews = async () => {
    try {
      const news_response = await fetch(
        "https://numbers.nikolakolevski.com/api/client/news"
      );
      const news_data = await news_response.json();
      setNews(news_data); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  const fetchYoutube = async () => {
    try {
      const response = await fetch(
        "https://numbers.nikolakolevski.com/api/youtube"
      );
      const data = await response.json();
      setYoutube(data[0]); // Update the services state with fetched data
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  // Use effect hook to fetch services whenever activeView changes
  useEffect(() => {
    if (selectedSegment === "services") {
      fetchServices();
    }
    if (selectedSegment === "prices") {
      fetchPackages();
    }
    if (selectedSegment === "news") {
      fetchNews();
      fetchYoutube();
    }
  }, [selectedSegment]);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (location.pathname === "/admin/dashboard") {
      return;
    } else {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setIsVisible(scrollTop > 100);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    // window.addEventListener('scroll', handleScroll);
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
  }, []);

  const mapOptions = {
    center: { lat: 41.9973462, lng: 21.4279956 },
    zoom: 14,
  };

  const [isHeaderVisible, setHeaderVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === "/admin/dashboard") {
        return;
      }
      const currentScrollPos = window.pageYOffset;
      const isScrolledUp = prevScrollPos > currentScrollPos;

      setHeaderVisible(isScrolledUp || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  function RequireAuth({ children }: { children: JSX.Element }) {
    const token = localStorage.getItem("token");

    if (token?.length === 0) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} />;
    }
    try {
      // const response = await fetch('https://numbers.nikolakolevski.com/home', {
      //   headers: {
      //     Authorization: token,
      //   },
      // });
      // if (!response.ok) {
      //   const errorData = await response.json();
      //   throw new Error(errorData.message);
      // }
      // const data = await response.json();
    } catch (error) {
      // setMessage(error.message);
    }

    return children;
  }

  const saveTokenToLocalStorage = (token: any) => {
    localStorage.setItem("token", token);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const handleLogin = (newToken: string) => {
    saveTokenToLocalStorage(newToken);
    navigate("/admin/dashboard");
  };

  const handleToBookView = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/presentation");
    // setSelectedSegment("booking");
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* HEADER */}
      <div hidden={location.pathname === "/login"} className="App">
        <div
          className={
            selectedSegment === "booking" ? "booking_header" : "header"
          }
          style={{
            position: isHeaderVisible ? "relative" : "relative",
            top: 0,
            background: isMenuOpened ? "#F6F4F2" : "white",
          }}
        >
          <div className="headerCss">
            <div
              className="logo"
              onClick={(e) => {
                if (location.pathname === "/admin/dashboard") {
                  return;
                } else {
                  navigate("/");
                  scrollToTop();
                  setSelectedSegment("home");
                  saveSelectedSegmentToLocalStorage("home");
                }
              }}
            >
              <img src={LogoImg} className="brand_logo_img" alt="logo" />
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: "2rem",
                }}
              >
                <div className="dropdown">
                  <div className="dropdown-header ">
                   <span className="mobile_hide"> <LanguageIcon
                      style={{ paddingRight: "0.5rem", color: "#013A4E" }}
                    /></span>
                    <span
                      style={{ color: "#013A4E" }}
                      className="hover mobile_show"
                      onClick={toggleDropdown}
                    >
                      {selectedLanguage === "en"
                        ? "EN"
                        : selectedLanguage === "mk"
                        ? "МK"
                        : selectedLanguage === "de"
                        ? "DE"
                        : ""}
                    </span>
                    <span
                      style={{ color: "#013A4E" }}
                      className="hover mobile_hide"
                      onClick={toggleDropdown}
                    >
                      {selectedLanguage === "en"
                        ? "English"
                        : selectedLanguage === "mk"
                        ? "Македонски"
                        : selectedLanguage === "de"
                        ? "Deutsch"
                        : ""}
                    </span>
                    <KeyboardArrowDownIcon
                      onClick={toggleDropdown}
                      className="hover"
                      style={{ paddingLeft: "0.5rem", color: "#013A4E" }}
                    />
                    {/* <KeyboardArrowUpIcon
                      onClick={toggleDropdown}
                      className="hover"
                      style={{ paddingLeft: "0.5rem", color:  "#013A4E", display:isMenuOpened ? "none":"flex" }}
                    /> */}
                  </div>
                  <div className={`mobile_hide dropdown-menu  ${isOpen ? "open" : ""}`}>
                    <span
                      onClick={(event: any) => {
                        changeLanguage("mk");
                      }}
                      className="hover selector"
                    >
                      <span className="mobile_show">MK</span>
                      <span className="mobile_hide"> Македонски</span>
                    </span>
                    <span
                      onClick={(event: any) => {
                        changeLanguage("en");

                        // setMenuOpened(true)
                      }}
                      className="hover selector"
                    >
                       <span className="mobile_show">EN</span>
                      <span className="mobile_hide"> English</span>
                    </span>
                    <span
                      onClick={(event: any) => {
                        changeLanguage("de");
                        // setMenuOpened(true)
                      }}
                      className="hover selector"
                    >
                       <span className="mobile_show">DE</span>
                      <span className="mobile_hide"> Deutch</span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={isMenuOpened ? "cancel_menu" : "hide"}
                hidden={isMenuOpened}
                onClick={(e) => {
                  setMenuOpened(!isMenuOpened);
                }}
              >
                {" "}
                <CloseIcon
                  style={{
                    color: "#013A4E",
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                />
              </div>
              <div
                className={!isMenuOpened ? "menu-icon" : "hide"}
                onClick={(e) => {
                  setMenuOpened(!isMenuOpened);
                }}
              >
                <div className="bar">
                  <div
                    style={{ backgroundColor: "#013A4E" }}
                    className="bar-colored bar-top"
                  ></div>
                </div>
                <div className="bar">
                  {/* <div className="spacer-bottom"></div> */}
                  <div
                    style={{ backgroundColor: "#013A4E" }}
                    className="bar-colored "
                  ></div>
                </div>
                <div className="bar">
                  {/* <div className="spacer-bottom"></div> */}
                  <div
                    style={{ backgroundColor: "#013A4E" }}
                    className="bar-colored "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={!isMenuOpened ? "menu_big_gove" : "menu_big"}>
          <div
           className="menu_content_left"
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className="hover menu_item"
                  onClick={() => {
                    switch (selectedLanguage) {
                      case "en": {
                        navigate("/about-us");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "mk": {
                        navigate("/за-нас");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "de": {
                        navigate("/deatch");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                    }
                  }}

                >
                  {getTranslation("pages.about_us")}
                </span>
                <div
                  style={{ backgroundColor: "#013A4E", display: "none" }} // Initially hide this div
                  className="colored-selector "
                ></div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className="hover menu_item"
                  onClick={() => {
                    switch (selectedLanguage) {
                      case "en": {
                        navigate("/our-team");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "mk": {
                        navigate("/нашиот-тим");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "de": {
                        navigate("/deatch");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                    }
                  }}
                >
                  {getTranslation("pages.our_team")}
                </span>
                <div
                  style={{ backgroundColor: "#013A4E", display: "none" }} // Initially hide this div
                  className="colored-selector "
                ></div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className="hover menu_item"
                  onClick={() => {
                    switch (selectedLanguage) {
                      case "en": {
                        navigate("/contact");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "mk": {
                        navigate("/контакт");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "de": {
                        navigate("/deatch");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                    }
                  }}
                >
                  {getTranslation("pages.contact")}
                </span>
                <div
                  style={{ backgroundColor: "#013A4E", display: "none" }} // Initially hide this div
                  className="colored-selector "
                ></div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className="hover menu_item"
                  onClick={() => {
                    switch (selectedLanguage) {
                      case "en": {
                        navigate("/");
                        window.open("https://nikolakolevski.com/blog/","_blank")
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "mk": {
                        navigate("/");
                        window.open("https://nikolakolevski.com/blog/","_blank")
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "de": {
                        navigate("/");
                        window.open("https://nikolakolevski.com/blog/","_blank")
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                    }
                  }}

                >
                  {getTranslation("pages.news")}
                </span>
                <div
                  style={{ backgroundColor: "#013A4E", display: "none" }} // Initially hide this div
                  className="colored-selector mobile_hide"
                ></div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className="hover menu_item"
                  onClick={() => {
                    switch (selectedLanguage) {
                      case "en": {
                        navigate("/legal-areas");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "mk": {
                        navigate("/легално");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                      case "de": {
                        navigate("/deatch");
                        setMenuOpened(!isMenuOpened);
                        break;
                      }
                    }
                  }}
                >
                  {getTranslation("pages.legal_areas")}
                </span>
                <div
                  style={{ backgroundColor: "#013A4E", display: "none" }} // Initially hide this div
                  className=" colored-selector"
                ></div>
              </div>
            </div>
            <div>
              <span className="contact_info_icons">
                <YouTubeIcon
                  style={{
                    background: "#013A4E",
                    color: "white",
                    width:'2.5rem',
                    height:'2.5rem',
                    padding: "0.2rem",
                    borderRadius: 20,
                    margin: "0.3rem",
                  }}
                />
                <InstagramIcon
                  style={{
                    background: "#013A4E",
                    color: "white",
                    width:'2.5rem',
                    height:'2.5rem',
                    padding: "0.2rem",
                    borderRadius: 20,
                    margin: "0.3rem",
                  }}
                />
                <FacebookIcon
                  style={{
                    background: "#013A4E",
                    color: "white",
                    width:'2.5rem',
                    height:'2.5rem',
                    padding: "0.2rem",
                    borderRadius: 20,
                    margin: "0.3rem",
                  }}
                />
                {/* <div  style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              <TikTokIcon></TikTokIcon></div> */}
                {/* <EmailIcon style={{background:'#DA327D', padding:'0.2rem', borderRadius:8, margin:'0.3rem'}} /> */}
              </span>
            </div>
          </div>
          <div
           className="menu_content_right"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <h1>Get in touch with us !</h1>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: "1rem 0rem",
              }}
            >
              <ThemeProvider theme={theme}>
                <TextField
                  name="Name"
                  className="textfield"
                  required
                  id="outlined-required"
                  placeholder="Name"
                />
              </ThemeProvider>

              <div style={{ width: "10%" }}></div>
              <ThemeProvider theme={theme}>
                <TextField
                  name="Surname"
                  className="textfield"
                  required
                  id="outlined-required"
                  placeholder="Surname"
                />
              </ThemeProvider>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: "1rem 0rem",
              }}
            >
              <ThemeProvider theme={theme}>
                <TextField
                  name="email"
                  className="textfield"
                  required
                  id="outlined-required"
                  placeholder="example@email.com"
                />
              </ThemeProvider>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: "1rem 0rem",
              }}
            >
              <ThemeProvider theme={theme}>
                <TextField
                  name="message"
                  className="textfield"
                  required
                  rows={5}
                  multiline={true}
                  id="outlined-required"
                  // label="Email"
                  placeholder="Hello..."
                  // onChange={handleValueChange}
                />
              </ThemeProvider>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: "1rem 0rem",
              }}
            >
              <span className="my_button_reverse">
                {getTranslation("submit").charAt(0).toUpperCase() +
                  getTranslation("submit").slice(1).toLocaleLowerCase()}
              </span>
            </div>
            <div
             className="contact_info"
            >
              <div className="dropdown">
                <div className="dropdown-header ">
                  <LocalPhoneIcon
                    style={{ paddingRight: "0.5rem", color: "#013A4E" }}
                  />
                  <span className="tablet_mobile">
                    +389 777 7777 7
                  </span>
                </div>
              </div>
              <div className="dropdown">
                <div className="dropdown-header ">
                  <EmailIcon
                    style={{ paddingRight: "0.5rem", color: "#013A4E" }}
                  />
                   <span className="tablet_mobile">
                    kolevski@gmail.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ROUTING  */}
      <Routes>
        <Route
          path="/"
          element={
            <Home
            isMenuOpened={isMenuOpened}
            />
          }
        />
        <Route path="/about-us" element={<About isInHomepageView={false} />} />
        <Route path="/за-нас" element={<About isInHomepageView={false} />} />
        <Route
          path="/contact"
          element={<Contact  isInHomepageView={false} />}
        />
        <Route
          path="/контакт"
          element={<Contact isInHomepageView={false} />}
        />
       <Route path="/our-team" element={<OurTeam isInHomepageView={false} />} />
       <Route path="/our-team/:employeeId" element={<OurTeam isInHomepageView={true} />} />
      {/* Add more routes as needed */}
        <Route
          path="/нашиот-тим"
          element={<OurTeam  isInHomepageView={false} />}
        />
         <Route path="/нашиот-тим/:employeeId" element={<OurTeam isInHomepageView={false} />} />

        <Route
          path="/legal-areas"
          element={
            <LegalAreas  isInHomepageView={false} />
          }
        />
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        {/* <Route path="/news/:newsId" element={<CurrentNews />} /> */}
        <Route
          path="/admin/dashboard"
          element={
            <RequireAuth>
              <AdminWelcome />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* Footer */}

      <div
        hidden={location.pathname === "/login" || isMenuOpened}
        id={location.pathname != "/login" ? "footer" : ""}
      >
        {/* {(selectedSegment ==="services" || selectedSegment ==="prices" || selectedSegment ==="home" ) && <div hidden={location.pathname === "/admin/dashboard" ? true:false} id="last_book" >
        <div style={{padding:'20px'}} >
          <div className=''>
            <p className='about-prod-header-book text-color-white'>{getTranslation("book_tour")}</p>
            <p className='about-prod-info-book  text-color-white'>{getTranslation("book_tour_info")}</p>
          </div>
          <div style={{display:'flex', justifyContent:'space-around'}}>
          <Button  onClick={handleToBookView} variant="outlined" sx={{ padding: '10px 20px', borderRadius: '20px', color: 'white', borderColor:'white', borderWidth:2 ,'&:hover': {
          borderColor: 'white' , border:'2px solid'
        },}}>{getTranslation("book_tour")}</Button>
          </div>
        </div>
      </div>} */}
        <div id="footer_container">
          <div id="footer_info">
            {/* <p className='brand_name_short'>{getTranslation('brand_name_short')}</p> */}
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {" "}
              <img
                src={LogoImg}
               className="footer_logo"
                alt="My Local Image"
              />
            </div>
            <div>
              {" "}
              <span id="brand_f">
                <p className="brand_name_long brand_copyright" >
                  @ 2023 Law Office Kolevski
                </p>
              </span>
            </div>
          </div>
          <div style={{ margin: "0.2rem" }}></div>
          <div className="footer_map">
            {/* <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBRTXFX_mbr-3wnbYJfAn97XtHmCClXzpw' }}
          defaultCenter={mapOptions.center}
          defaultZoom={mapOptions.zoom}
        /> */}
            <span style={{ display: "flex" }}>
              <EmailIcon
                className="home_menu_icon"
              />
              <LinkedInIcon
                className="home_menu_icon"
              />
              <FacebookIcon
                className="home_menu_icon"
              />
              {/* <div  style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              <TikTokIcon></TikTokIcon></div> */}
              {/* <EmailIcon style={{background:'#DA327D', padding:'0.2rem', borderRadius:8, margin:'0.3rem'}} /> */}
            </span>
          </div>
        </div>
      </div>
      {/* Navigation button */}
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={scrollToTop}
        style={{
          display: isVisible ? "flex" : "none",
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "9999",
          background: "#DA327D",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <KeyboardArrowUpIcon />
      </Button>
    </>
  );
}

export default App;
function useAuth() {
  throw new Error("Function not implemented.");
}
function determineCurrentPage() {
  throw new Error("Function not implemented.");
}
