import React, { useState ,ChangeEvent , FormEvent, useEffect } from 'react';


import { ServiceItem, ServiceOption } from '../../interfaces';
import { getTranslation } from '../../translation';
import { Select, MenuItem, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';


function ServicesComponent ({services, openCreateView , refreshServices, isSetCreateServices, handleCloseCreateServices}:any) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [nextService, setNextService] = useState(0)
  const [isSelected , setIsSelected] = useState<Boolean>(false)
  const [isCreate , setIsCreateOpen] = useState<Boolean>(false)
  const [isLoading , setIsLoading] = useState<Boolean>(false)
  const [failed, setFailed] = useState(false);
  const [open, setOpen] = useState(false);
  const [successfull, setSuccessfull] = useState(false);
  const [toast_message, setToastMessage] = useState('empty_fields');
  const [selectedService, setSelectedService] = useState<ServiceItem>({
    uuid:"",
    created:"",
    mk_description:"",
    mk_name:"",
    en_description:"",
    en_name:"",
    price:{
      by_hour:0,
      is_offer:false,
      price:0,
      sale_price:0,
    },
    src:"",
    status:true,
    updated:""
  });


  React.useEffect(() => {
    openCreateView.current = openCreateServiceView

  }, [])

  function openCreateServiceView() {
    setIsSelected(true)
    handleOpenCreate()
    clearSelectedService()
  }

  const options: ServiceOption[] = [
    { value: '0', label: getTranslation('time') },
    { value: '1', label: getTranslation('offer') },
  ];

  const handleOpenCreate = () => {
    setIsCreateOpen(true);
  };

  const handleCloseCreate = () => {
    setIsCreateOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // loading
  const StartLoading = () => {
    setIsLoading(true);
  };

  const StopLoading = () => {
    setIsLoading(false);
  };
  // success
  const handleOpenSuccessfull = () => {
    setSuccessfull(true);
  };

  const handleCloseSuccessfull = () => {
    setSuccessfull(false);
  };

  // Failed
  const handleOpenFailed = () => {
    setFailed(true);
  };

  const handleCloseFailed = () => {
    setFailed(false);
  };

  const clearSelectedService = () => {
    setSelectedService({
      uuid:"",
      created: "",
      mk_description: "",
      mk_name: "",
      en_name:"",
      en_description:"",
      price: {
        by_hour: 0,
        is_offer: false,
        price: 0,
        sale_price: 0,
      },
      src: "",
      status:true,
      updated: "",
    });
  };

  function validateServiceChanges(index : number, current_service : ServiceItem) {
    if (
      selectedService.mk_name !== services[currentIndex].mk_name ||
      selectedService.en_name !== services[currentIndex].en_name ||
      selectedService.price.price !== services[currentIndex].price.price ||
      selectedService.price.by_hour !== services[currentIndex].price.by_hour ||
      selectedService.mk_description !== services[currentIndex].mk_description ||
      selectedService.en_description !== services[currentIndex].en_description ||
      selectedService.price.is_offer !== services[currentIndex].price.is_offer ||
      selectedService.status !== services[currentIndex].status ||
      selectedService.src !== services[currentIndex].src
    ) {

      setNextService(index)
      handleOpen()
    } else {
      setCurrentIndex(index)
      setSelectedService(current_service)
    }
  }
  const createNewService = async () => {
    try {
      StartLoading()
      await axios.post(`https://numbers.nikolakolevski.com/api/services/create`, selectedService, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      StopLoading()
      handleOpenSuccessfull()
      clearSelectedService()
      refreshServices()

    } catch (error) {
      // setError(error.message);
      StopLoading()
      handleOpenFailed()
    }
  };

  const saveChanges = async () => {
    try {
      StartLoading()
      await axios.post(`https://numbers.nikolakolevski.com/api/services/update/${selectedService.uuid}`, selectedService, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      StopLoading()
      handleOpenSuccessfull()
      refreshServices()
    } catch (error) {
      StopLoading()
      handleOpenFailed()
      // Handle error, if necessary
      console.error('Error updating service:', error);
    }
  };

  const deleteCurrentService = async () => {
    try {
      StartLoading();

      await axios.delete(`https://numbers.nikolakolevski.com/api/services/delete/${selectedService.uuid}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      StopLoading();
      clearSelectedService()
      setIsSelected(false)
      handleOpenSuccessfull();
       handleCloseDeleteDialog()
      refreshServices();
    } catch (error) {
      StopLoading()
      handleOpenFailed()
      console.error('Error deleting service:', error);
    }
  };
  // delete dialog
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleCloseDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteOpen(true);
  };

  const customDeleteStyles = {
    content: {
      width: '25%',
      height: '15%',
      margin: 'auto',
      borderRadius: '8px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'white',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  return (
<div className='service_container'>
      {/* LEFT SIDE : Show all the services    */}
      <div  className='services'>
      {services.map((current_service:ServiceItem, index:number) => {
        if (!current_service) {
          return
        }
        return (
          <div  key={current_service.uuid} className={index == currentIndex  && !isCreate && isSelected ? "service" :"service_not_selected"} onClick={()=>{
            handleCloseCreateServices()
            handleCloseCreate()
            if (isSelected && selectedService.mk_name.length != 0) {
              validateServiceChanges(index, current_service)
            } else {
              setCurrentIndex(index)
              setSelectedService(current_service)
              setIsSelected(true)
            }

          }}>
          {current_service.mk_name}
          </div>
        );
        })}
      </div>
      {/*RIGHT SIDE:   */}
      <div className='edit_service'>
       { isSelected && !isLoading && <div className='service_info_container'>
       <div className="service_row" style={{marginBottom:'0rem', paddingBottom:'0rem'}}>
        <div>
        </div>
        <div>
        <FormControlLabel
          control={<Switch checked={selectedService.status} onChange={()=>{
            setSelectedService((prevService) => ({
              ...prevService,
             status:!selectedService.status
            }));
          }} />}
          label={getTranslation(selectedService.status ? 'buttons.active' :'buttons.not_active')}
        />
          <Button
            style={{margin:'0.5rem', color:'black', border:'1px solid  black'}}
            startIcon={<SaveIcon/>}
            onClick={(e:any)=>{
              if (!isCreate) {
                saveChanges()
              } else {
                createNewService()
              }
              }} variant="outlined">{getTranslation(isCreate ? "buttons.create" : "buttons.save_changes")}</Button>
          {!isCreate &&
            <Button

           style={{margin:'0.5rem', color:'black', border:'1px solid  black'}}
           startIcon={<DeleteIcon/>}
             onClick={(e)=>{
              handleOpenDeleteDialog()
         }} variant="outlined">{getTranslation("buttons.clear_timeslot")}</Button>
            }
            </div>
        </div>
      <div className='service_row' style={{marginTop:'0rem', paddingTop:'0rem'}}>
      <span   className='textfield service_row'>
        <Select value={selectedService.price.is_offer ? options[1].value : options[0].value } onChange={(event:any)=>{
          // setSelectedOption(event.target as ServiceOption);
          const target_value = event.target as ServiceOption
          if (target_value.value == '1' ) {
            setSelectedService((prevService) => ({
              ...prevService,
              price: {
                ...prevService.price,
                is_offer: true,
              }
            }));
          } else {
            setSelectedService((prevService) => ({
              ...prevService,
              price: {
                ...prevService.price,
                is_offer: false,
              }
            }));
          }
        }}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        </span>
        <span   className='textfield service_row'>
          <TextField
            name="name"
            required
            type='number'
            sx={{
              width:'100%'
            }}
            value={selectedService.price?.price}
            id="outlined-required"
            label={getTranslation(!selectedService.price.is_offer ? 'labels.first_hour' :'labels.min_offer')}
            // placeholder="John"
            onChange={(e: any) => {
              const { value } = e.target;
              setSelectedService((prevService) => ({
                ...prevService,
                price: {
                  ...prevService.price,
                  price: Number(value)
                }
              }));
            }}
            />
            {!selectedService.price?.is_offer ? <>
            <TextField
            name="name"
            required
            type='number'
            sx={{
              marginLeft:"2rem"
              // width:'20%'
            }}
            value={selectedService?.price?.by_hour}
            id="outlined-required"
            label={getTranslation('labels.by_hour')}
            // placeholder="John"
            onChange={(e: any) => {
              const { value } = e.target;
              setSelectedService((prevService) => ({
                ...prevService,
                price: {
                  ...prevService.price,
                  by_hour: Number(value)
                }
              }));
            }}/></>:<></>}

            </span>
      </div>
        <div className='service_row'>
          <span   className='textfield'>
          <TextField
            name="name"
            required
            sx={{
              width:'100%'
            }}
            label={getTranslation('labels.mk_name')}
            value={selectedService.mk_name}
            id="outlined-required"
            // label={getTranslation('labels.package_name')}
            placeholder="John"
            onChange={(e: any) => {
              const { value } = e.target;
              setSelectedService((prevService) => ({
                ...prevService,
                mk_name : value
              }));
            }}
            />
        </span>
        <span   className='textfield'>
          <TextField
            name="name"
            required
            sx={{
              width:'100%'
            }}
            label={getTranslation('labels.en_name')}
            value={selectedService.en_name}
            id="outlined-required"
            // label={getTranslation('labels.package_name')}
            placeholder="John"
            onChange={(e: any) => {
              const { value } = e.target;
              setSelectedService((prevService) => ({
                ...prevService,
                en_name: value
              }));
            }}
            />
        </span>

        </div>
        <div className='service_row'>
          <span   className='textfield'>
          <TextField
            name="name"
            required
            label={getTranslation('labels.mk_description')}
            value={selectedService.mk_description}
             id="outlined-multiline-static"
            // label={getTranslation('labels.package_description')}
            placeholder="John"
            multiline
            rows={10}
            sx={{
              width:'100%'
            }}
            onChange={(e: any) => {
              const { value } = e.target;
              setSelectedService((prevService) => ({
                ...prevService,
                mk_description : value
              }));
            }}
            />
        </span>
        <span   className='textfield'>
          <TextField
            name="name"
            required
            label={getTranslation('labels.en_description')}
            value={selectedService.en_description}
             id="outlined-multiline-static"
            // label={getTranslation('labels.package_description')}
            placeholder="John"
            multiline
            rows={10}
            sx={{
              width:'100%'
            }}
            onChange={(e: any) => {
              const { value } = e.target;
              setSelectedService((prevService) => ({
                ...prevService,
                en_description : value
              }));
            }}
            />
        </span>
        </div>
        <div className='service_row' style={{margin:'1rem'}}>
        <TextField
            name="name"
            required
            sx={{
              width:'90%'
            }}
            label={getTranslation('labels.img_url')}
            value={selectedService.src}
            id="outlined-required"
            // placeholder="John"
            onChange={(e: any) => {
              const { value } = e.target;
              setSelectedService((prevService) => ({
                ...prevService,
                src:value
              }));
            }}
            />
        </div>
        <div className='service_row' style={{margin:'1rem'}}>

        <img
             alt="microphone"
             src={selectedService.src}
             style={{ width: '50%', borderRadius:18 }}
           />
        </div>

        </div>
       }
       {isLoading &&  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'25vh' }}>
            <CircularProgress size={100} sx={{color:'#DA327D'}}/>
        </div>}
    </div>
    <Modal
      isOpen={isDeleteOpen}
      onRequestClose={handleCloseDeleteDialog}
      contentLabel="Dialog"
      // style={{width:'10rem', height:'10rem'}}
      style={customDeleteStyles}

    >
      <h2>Are you sure you want to delete ?</h2>
      <div style={{minHeight:'3rem', width:'100%'}}>
          {/* <div style={{display:!(currentStep === 'booking') ? 'none':'flex', justifyContent:'end'}}> */}
            <Button
                style={{margin:'0.5rem 0rem 0.5rem 0.5rem', color:'black', border:'1px solid  black'}}
                startIcon={<CloseIcon/>}
                onClick={(e:any)=>{
                  handleCloseDeleteDialog()
                  }} variant="outlined">{getTranslation("buttons.close")}</Button>
            <Button
                style={{margin:'0.5rem', color:'black', border:'1px solid  black'}}
                startIcon={<SaveIcon/>}
                onClick={(e:any)=>{
                   deleteCurrentService()
                  }} variant="outlined">{getTranslation("buttons.yes")}</Button>

          {/* </div> */}
        </div>
    </Modal>
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleClose} severity="info" variant="filled">
          <div style={{display:'flex', alignContent:'center', alignItems:'center'}}>
          <span style={{paddingLeft:'1rem'}}>{getTranslation('toast.service_changes')} </span>
          <span><Button variant="outlined" sx={{
            marginLeft:'1rem',
                    bottom: "0px",
                    left: "0px",
                    // width: "250px",
                    // background: 'blue',
                    // height: "1.5rem",
                    fontSize: "1rem",
                    color: "white",
                    border: "0",
                    fontWeight: 500,
                    '&:hover': {
                      borderColor: 'white' , color: "white", background: 'transparent',
                    },
                    }}
                    onClick={(e:any) => {
                      handleClose()
                      saveChanges()
                    }}
                    >{getTranslation('buttons.yes')}</Button></span>
                    <span><Button variant="outlined" sx={{
                      marginLeft:'1rem',
                    bottom: "0px",
                    left: "0px",
                    // width: "250px",
                    // background: 'blue',
                    // height: "3.5rem",
                    fontSize: "1rem",
                    color: "white",
                    border: "0",
                    fontWeight: 500,
                    '&:hover': {
                      borderColor: 'white' , color: "white", background: 'transparent',
                    },
                    }}
                    onClick={(e:any) => {
                      handleClose()
                      setCurrentIndex(nextService)
                      setSelectedService(services[nextService])
                    }}

                    >{getTranslation('buttons.no')}</Button></span>
          </div>
        </Alert>
      </Snackbar>
      <Snackbar open={successfull} autoHideDuration={3000} onClose={handleCloseSuccessfull}>
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleCloseSuccessfull} severity="success" variant="filled">
         <span>{getTranslation('toast.changes_saved')}</span>
        </Alert>
      </Snackbar>
      <Snackbar open={failed} autoHideDuration={3000} onClose={handleCloseFailed}>
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleCloseFailed} severity="error" variant="filled">
         <span>{getTranslation('toast.not_saved')}</span>
        </Alert>
      </Snackbar>
</div>
  );
}

export default ServicesComponent ;