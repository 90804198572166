import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setDefaultLanguage, getTranslation } from "../translation";
import "../App.css";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { genericComponentProps, NewsItem } from "../interfaces";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { url } from "inspector";

export default function LegalAreas({ isInHomepageView }: genericComponentProps) {
  const [currentView, setCurrentView] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: "",
    x: 0,
    y: 0,
  });
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // Remove border
                // borderBottomLeftRadius: '20px', // Add border radius to bottom-left
                // borderBottomRightRadius: '20px', // Add border radius to bottom-right
                boxShadow: "0px 16px 12px -12px rgba(0, 0, 0, 0.2)", // Box shadow at the bottom
              },
              "&.Mui-focused fieldset": {
                border: "1px solid gray", // Add gray border when focused
                borderBottomLeftRadius: "20px", // Maintain border radius on bottom-left when focused
                borderBottomRightRadius: "20px", // Maintain border radius on bottom-right when focused
              },
            },
          },
        },
      },
    },
  });
  const handleMouseEnter = (event: any) => {
    let countryName = "See Location";

    // Replace underscores with spaces

    // setTimeout(() => {
    setTooltip({
      visible: true,
      content: countryName,
      x: event.clientX + 30,
      y: event.clientY,
    });
    // }, 1000);
  };

  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  function WorldMap2() {
    return (
      <>

      </>
    );
  }

  return (
    <div id="about_us">
      <span style={{ fontSize: "3.5rem" }}>
        {getTranslation("legal_areas.header")}
        <hr
          style={{
            background: "#013A4E",
            height: "2px",
            margin: "0.3rem 0rem 0rem 0rem",
            width: "5.5rem",
          }}
        />
      </span>
      <div
        style={{
          height: "75vh",
          display: "flex",
        }}
      >

      </div>
    </div>
  );
}
