import React, { ChangeEvent, useState } from 'react';
import { getTranslation } from '../../translation';
import TextField from '@mui/material/TextField';
import { NewsItem } from '../../interfaces';
import IconButton from '@mui/material/IconButton';
import '../../styles/NewsComponent.css'
import EditIcon from '@mui/icons-material/Edit';
import Modal from 'react-modal';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import CloseIcon from '@mui/icons-material/Close';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ArticleIcon from '@mui/icons-material/Article';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import NewsDetailsComponent from './NewsDetailsComponent';
import axios from 'axios';
function NewsComponent ({news,refreshNews,isSetCreateNews, handleCloseCreateNews}:any) {
  const [isEditView, setIsEditView] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleCloseNewsComponentDialog = () => {
    handleCloseCreateNews(false);
    setIsEditView(false)
    refreshNews();
    handleOpenSuccessfull()
  }

  const handleCloseNewsComponentDialogNormal = () => {
    handleCloseCreateNews(false);
    setIsEditView(false)

    // handleOpenSuccessfull()
  }
  const setIsEditViewChange =(value : boolean) =>{
    setIsEditView(value)
  }

  const [successfull, setSuccessfull] = useState(false);
   const handleOpenSuccessfull = () => {
    setSuccessfull(true);
  };

  const handleCloseSuccessfull = () => {
    setSuccessfull(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

const createNews =  {
  alt: "",
  src: "https://images.pexels.com/photos/3097112/pexels-photo-3097112.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  category: "",
  title: "",
  first_description: "",
  second_description: "",
  body:'',
  subtitle:'',
  created:'',
  updated:'',
} as NewsItem


  const customCreateDialogStyles = {
    content: {
      width: '40%',
      height: '6%',
      margin: 'auto',
      borderRadius: '8px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'white',
      zIndex:999,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const deleteCurrentNews = async () => {
    try {
      await axios.delete(`https://numbers.nikolakolevski.com/api/news/delete/${news[currentIndex].uuid}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      handleOpenSuccessfull();
      handleCloseDeleteDialog()
      refreshNews();
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  return (
    <div>
        <div className="news">
        <div className="news_header">
          <div className="table-heading">{getTranslation("labels.title")}</div>
          <div className="table-heading">{getTranslation("labels.category")}</div>
          <div className="table-heading">{getTranslation("labels.description")}</div>
          <div className="table-heading">{getTranslation("labels.created")}</div>
          <div className="table-heading">{getTranslation("labels.actions")}</div>
        </div>
        {news?.map((current_news:NewsItem, index:number) => {
          function setCurrentNewsIndex(index: number) {
            setIsEditView(true)
            setCurrentIndex(index)
          }

          return (
            <div className="news_not_selected" key={current_news.title}>
              <div>{current_news.title}</div>
              <div>{current_news.category}</div>
              <div>{current_news.first_description.length > 20 ? current_news.first_description.substring(0,20) +"..." : current_news.first_description}</div>
              <div>{current_news.created}</div>
              <div style={{ display: "flex", justifyContent:'center' }}>
                <IconButton onClick={(e)=>{
                  setCurrentNewsIndex(index)

                }} aria-label="edit" id="edit_button">
                  <EditIcon />
                </IconButton>
                <div style={{width:'10%'}}> </div>
                <IconButton onClick={()=>{
                  setCurrentIndex(index)
                  handleOpenDeleteDialog()
                }} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
                <div>
              </div>
              </div>
            </div>
          );
        })}
      </div>

      <NewsDetailsComponent currentItem={isEditView ?news[currentIndex]:createNews} isEditView={isEditView} openDialog={handleCloseNewsComponentDialog} openDialogNormal={handleCloseNewsComponentDialogNormal} openModalDialog={isEditView ? isEditView : isSetCreateNews}/>

    <Modal
      isOpen={isDeleteDialogOpen}
      onRequestClose={handleCloseDeleteDialog}
      contentLabel="Dialog"
      // style={{width:'10rem', height:'10rem'}}
      style={customCreateDialogStyles}

    >
      {/* {news.length > 0 &&     <div className='create_package'>
          {getTranslation("Are you sure you want to delete ")} {news[currentIndex].title} ?
      </div>} */}
      <div style={{minHeight:'3rem', width:'100%'}}>
        <div style={{display:'flex', justifyContent:'end'}}>
          <Button
                style={{margin:'0.5rem 0rem 0.5rem 0.5rem', color:'black', border:'1px solid  black'}}
                startIcon={<CloseIcon/>}
                onClick={(e:any)=>{
                  handleCloseDeleteDialog()
                  }} variant="outlined">{getTranslation("buttons.close")}</Button>
            <Button
                style={{margin:'0.5rem', color:'black', border:'1px solid  black'}}
                startIcon={<SaveIcon/>}
                onClick={(e:any)=>{
                  // to do
                  deleteCurrentNews()
                  handleCloseDeleteDialog()
                  }} variant="outlined">{getTranslation("buttons.delete")}</Button>

          </div>
        </div>
    </Modal>
    <Snackbar open={successfull} autoHideDuration={3000} onClose={handleCloseSuccessfull}>
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleCloseSuccessfull} severity="success" variant="filled">
         <span>{getTranslation('toast.successfull')}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default NewsComponent ;