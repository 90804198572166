// LoginPage.js

import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import "./../styles/LoginPage.css"; // Import the CSS file for styling
import TextField from "@mui/material/TextField";

import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';

import axios from "axios";

interface ChildComponentProps {
  sendBooleanToParent: (value: boolean) => void;
}

interface LoginParams {
  email: string;
  pass: string;
}

interface LoginProps {
  onLogin: (token: string) => void;
}

const LoginPage: React.FC<LoginProps> = ({ onLogin }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(false);
  const [error_message, setErrorMessage] = useState("");
  const handleOpenError = () => {
    setError(true);
  };

  const handleCloseError = () => {
    setError(false);
  };
  const [formData, setFormData] = useState<LoginParams>({
    pass: "",
    email: "",
  });

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch("https://numbers.nikolakolevski.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.pass,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      console.log(data);
      localStorage.setItem("token", data); // Store token in local storage

      onLogin(data);
    } catch (error:any) {
      setError(true);
      console.log(error)
      setErrorMessage(error.message)
    }
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="login-form-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>SKOPJE PODCAST STUDIO</h2>
        <TextField
          name="email"
          className="textfield"
          required
          id="outlined-required"
          label="Email"
          placeholder="example@email.com"
          onChange={handleValueChange}
        />
        <TextField
          name="pass"
          className="textfield"
          required
          id="outlined-required"
          label="Password"
          placeholder="Password"
          onChange={handleValueChange}
        />
        <button type="submit">Login</button>
      </form>

      <Snackbar open={error} autoHideDuration={3000} onClose={handleCloseError}>
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleCloseError} severity="error" variant="filled">
         <span>{error_message}</span>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LoginPage;
